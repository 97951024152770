import React from 'react';
import {graphql} from 'gatsby';
import loadable from '@loadable/component';

const PageHeader = loadable(() => import('../components/PageHeader'));
const Layout = loadable(() => import('../components/Layout'));
const SoinCard = loadable(() => import('../components/soins/SoinCard'));
import './SoinsPage.scss';

// Export Template for use in CMS preview
export const SoinsPageTemplate = ({
                                      title,
                                      subtitle,
                                      featuredImage,
                                      soins
                                  }) => (
    <main>
        <PageHeader
            title={title}
            subtitle={subtitle}
            carousel=""
            backgroundImage={featuredImage}
        />

        <div className="mt-8 p-8 container text-center">
        {!!soins.length && (
            <div className="SoinsSection--Grid flex mt-8 mb-8 flex-wrap justify-center">
                {soins.map((soin, index) => (
                    <SoinCard key={soin.title + index} {...soin} />
                ))}
            </div>
        )}
        </div>
    </main>
);

const SoinsPage = ({data: {page, soins}}) => (
    <Layout
        meta={page.frontmatter.meta || false}
        title={page.frontmatter.title || false}
    >
        <SoinsPageTemplate {...page} {...page.frontmatter}
                           soins={soins.edges.map(soin => ({
                               ...soin.node,
                               ...soin.node.frontmatter,
                               ...soin.node.fields
                           }))}
                           body={page.html}/>
    </Layout>
);

export default SoinsPage

export const pageQuery = graphql`
  query SoinsPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      ...Gallery
      html
      frontmatter {
        title
        template
        subtitle
        featuredImage
      }
    }
    
    soins: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "soins" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            featuredImage
          }
        }
      }
    }
  }
`;
